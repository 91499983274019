import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import PageHeader from '~components/PageHeader'
import FeedbackForm from '~components/FeedbackForm'
import RenderModules from '~components/RenderModules'
import Intro from '~components/Intro'
import HomepageHeader from '~components/HomepageHeader'
import ViewCart from '~components/ViewCart'
import { useSiteState } from '~context/siteContext'

const Page = ({ data }) => {

	const page = data.sanityPage
	const [ siteState ] = useSiteState()

	return(
		<>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			{page?.slug?.current === 'home' 
				? <HomepageHeader content={page?.header} fullImage/>
				: <PageHeader content={page?.header} page={page}/>
			}
			{/* {page?.slug?.current === 'panda-learning-hub' && <ViewCart />} */}
			{page?.introduction && <Intro content={page}  />}

			<RenderModules modules={page?.modules} />
			{page.showFeedbackForm &&
				<FeedbackForm />
			}
			<DataViewer data={page} name="page" />
		</>
	)
}

Page.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query PageQuery($id: String) {
    sanityPage(id: {eq: $id}) {
      title
			slug {
				current
			}
			section
			header {
				...pageHeader
			}
			associatedChecklist {
				slug {
					current
				}
			}
			introduction {
				headline
				text: _rawText(resolveReferences: {maxDepth: 5})
			}
			modules{
				... on SanityArticleCollection {
					...articleCollection
				}
				... on SanityStoryCollection {
					...storyCollection
				}
				... on SanityProductCollection {
					...productCollection
				}
				... on SanitySignsSymptoms {
					...signsSymptoms
				}
				... on SanityHelpLine {
					_key
					_type
					include
				}
				... on SanityChatBotModule {
					_key
					_type
					include
				}
				... on SanityChecklist {
					_key
					_type
					include
				}
				... on SanityChecklistModule {
					_key
					_type
					include
				}
				... on SanityQuickLinks {
					...quickLinks
				}
				... on SanitySmartSearch {
					...smartSearch
				}
				... on SanityFaqBlock {
					...faqBlock
				}
				... on SanityTextImage {
					...textImage
				}
				... on SanityFreeText {
					...freeText
				}
				... on SanityCenteredText {
					...centeredText
				}
				... on SanityFileCollection {
					...fileCollection
				}
				... on SanityTeam {
					...team
				}
				... on SanityLogoGrid {
					...logoGrid
				}
				... on SanityVideo {
					...video
				}
				... on SanityPodcast {
					...podcast
				}
				... on SanityContactForm {
					...contactForm
				}
				... on SanityFormAssemblyEmbed {
					...formAssemblyEmbed
				}
				... on SanityTextColumns {
					...textColumns
				}
				... on SanityQuote {
					...quote
				}
				... on SanityCampaign {
					...campaign
				}
				... on SanityEmbed {
					_key
					_type
					embedCode
				}
			}
			showFeedbackForm
      seo{
				...seo
			}
    }
  }
`

export default Page