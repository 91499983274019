import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import Button from '~components/Button'

const ViewCart = ({ className }) => {
	return (
		<Wrap className={className}>
			<Container>
				<Button 
					outline 
					icon={'Cart'} 
					link={{
						linkType: 'internal', 
						document:{
							slug: {
								current: 'cart'
							}
						}
					}}
				>
					View Cart
				</Button>
			</Container>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: var(--l) 0;
`
const Container = styled.div`
	grid-column: span 12;
	justify-self: end;
	${mobile}{
		justify-self: start;
	}
`

ViewCart.propTypes = {
	className: PropTypes.string
}

export default ViewCart